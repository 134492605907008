/** @format */

import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { format, addDays } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";
import { CalendarIcon } from "lucide-react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { assistantSupabase } from "../supabaseClient";
import { ScrollArea, ScrollAreaScrollbar, ScrollAreaThumb, ScrollAreaViewport } from "@radix-ui/react-scroll-area";
import { SelectViewport } from "@radix-ui/react-select";

const FormSchema = z.object({
  appointmentDate: z.date({
    required_error: "An appointment date is required.",
  }),
  appointmentTime: z.string().optional(),
});

export default function EditAssistantAppointmentForm({
  appointment,
  onUpdate,
  user
}: any) {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      appointmentDate: new Date(appointment.fecha),
      appointmentTime: format(new Date(appointment.fecha), "HH:mm"),
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [availableAppointments, setAvailableAppointments] = useState<any[]>([]);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [availableTimes, setAvailableTimes] = useState<string[]>([]);

  useEffect(() => {
    fetchAvailableAppointments();
  }, []);

  useEffect(() => {
    getAvailableDates();
  }, [availableAppointments]);

  useEffect(() => {
    getAvailableTimes(appointment.fecha);
  }, [appointment.fecha, availableAppointments]);

  const fetchAvailableAppointments = async () => {
    const { data } = await assistantSupabase
      .from("appointment")
      .select("*")
      .eq("veterinario", appointment.doctor_id)
      .eq("estado", "disponible")
      .gte("fecha", new Date().toISOString());
    if (data) {
      setAvailableAppointments(data!);
    }
  };

  const getAvailableDates = () => {
    const uniqueDates = Array.from(
      new Set(
        availableAppointments.map((appointment: any) =>
          format(new Date(appointment.fecha), 'yyyy-MM-dd')
        )
      )
    ).map((dateString: any) => new Date(dateString));
    setAvailableDates(uniqueDates);
  }

  const getAvailableTimes = async (date: Date) => {
    const times = availableAppointments
      .filter((app: any) => format(date, 'yyyy-MM-dd') === format(new Date(app.fecha), 'yyyy-MM-dd'))
      .map((app: any) =>
        format(toZonedTime(app.fecha, "UTC"), "HH:mm")
      )
      .sort();
    if (times) {
      setAvailableTimes(times); // Ensure unique times
    }
  };


  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    const { appointmentDate, appointmentTime } = data;
    if (!appointmentTime) {
      form.setError("appointmentTime", {"message": "Appointment time is required"});
      return;
    }

    const [hours, minutes] = appointmentTime.split(":");

    const newAppointmentDateTimeUTC = new Date(
      Date.UTC(
        appointmentDate.getFullYear(),
        appointmentDate.getMonth(),
        appointmentDate.getDate(),
        parseInt(hours, 10),
        parseInt(minutes, 10)
      )
    );

    try {
      // Cancel previous appointment
      await assistantSupabase.from("appointment").update({
        "estado": "disponible",
        "user_id": null,
        "motivo": null,
        "confirmado": false
      }).eq("id", appointment.id)

      // Create new appointment
      const { error } = await assistantSupabase.from("appointment").update({
        "user_id": user.id,
        "estado": "reservada",
        "motivo": appointment.motivo,
      }).eq("veterinario", appointment.doctor_id).eq("fecha", newAppointmentDateTimeUTC.toISOString()).eq("estado", "disponible");
      if (error) {
        onUpdate();
      } else {
        throw new Error("Error updating appointment")
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex flex-col space-y-4">
          {/* Date Selection */}
          <FormField
            control={form.control}
            name="appointmentDate"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Fecha</FormLabel>
                <Popover open={isOpen} onOpenChange={setIsOpen}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button variant="outline" className="w-full text-left">
                        {field.value
                          ? format(field.value, "PPP")
                          : "Escoge una fecha"}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={(date) => {
                        field.onChange(date);
                        form.setValue("appointmentTime", '')
                        setIsOpen(false);
                        if (date) {
                          getAvailableTimes(date);
                        }
                      }}
                      disabled={(date) => !availableDates.map(d => format(addDays(d, 1), 'yyyy-MM-dd')).includes(format(date, 'yyyy-MM-dd'))}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Time Selection */}
          <FormField
            control={form.control}
            name="appointmentTime"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Hora</FormLabel>
                  <Select onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Escoge una hora"/>
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <ScrollArea className="h-60" type="auto">
                        <SelectViewport>
                          <ScrollAreaViewport className="h-60">
                          {availableTimes.length > 0 ? (
                            availableTimes.map((time) => (
                              <SelectItem key={time} value={time}>
                                {time}
                              </SelectItem>
                            ))
                          ) : (
                            <SelectItem disabled value="no-available-times">
                              No hay tiempos disponibles
                            </SelectItem>
                          )}
                          </ScrollAreaViewport>
                        </SelectViewport>
                        <ScrollAreaScrollbar
                          className="ScrollAreaScrollbar"
                          orientation="vertical"
                        >
                          <ScrollAreaThumb/>
                        </ScrollAreaScrollbar>
                      </ScrollArea>
                    </SelectContent>
                  </Select>
                </FormItem>
              );
            }}
          />
        </div>

        <Button type="submit" className="w-full">
          Guardar Cambios
        </Button>
      </form>
    </Form>
  );
}
