/** @format */

import React from "react";
import { CalendarIcon, Clock, Edit2, Trash } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import EditAssistantAppointmentForm from "./EditAssistantAppointmentForm";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { assistantSupabase } from "../supabaseClient";


interface AssistantAppointmentListProps {
  appointments: any[];
  fetchAppointments: () => void;
  user: any;
}

const AssistantAppointmentList: React.FC<AssistantAppointmentListProps> = ({
  appointments,
  fetchAppointments,
  user,
}) => {
  const onCancel = async (id: number) => {
    try {
      await assistantSupabase.from("appointment").update({
        "estado": "disponible",
        "user_id": null,
        "motivo": null,
        "confirmado": false
      }).eq("id", id);
      fetchAppointments();
    } catch (error) {
      console.error("Error canceling appointment", error);
    }
  }
  return (
    <div>
      {appointments && appointments.length > 0 && (
        <div>
          <h2 className="text-l font-semibold mb-4">
            Appointments
          </h2>
          <div className="grid grid-cols-1 gap-3">
            {appointments.map((appointment) => (
              <Card key={appointment.id} className="p-4">
                <CardHeader className="flex flex-row items-center gap-2 p-2">
                  <CalendarIcon className="text-gray-500 w-5 h-5" />
                  <CardTitle className="text-sm md:text-md">
                    {toZonedTime(new Date(appointment.fecha), 'UTC').toDateString()}
                  </CardTitle>
                  <Clock className="text-gray-500 w-5 h-5 ml-auto" />
                  <span className="text-sm">
                    {format(toZonedTime(new Date(appointment.fecha), "UTC"), "HH:mm")}
                  </span>
                  <Popover>
                    <PopoverTrigger asChild>
                      <button className="ml-2">
                        <Edit2 className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer" />
                      </button>
                    </PopoverTrigger>
                    <PopoverContent className="p-4 bg-white rounded-lg shadow-lg w-[calc(100vw-2rem)] max-w-md mx-auto left-0 right-0">
                      <EditAssistantAppointmentForm
                        appointment={appointment}
                        onUpdate={fetchAppointments}
                        user={user}
                      />
                    </PopoverContent>
                  </Popover>
                  <button className="ml-2" onClick={() => {onCancel(appointment.id)}}>
                    <Trash className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer">
                    </Trash>
                  </button>
                </CardHeader>
                <CardContent className="p-2">
                  <div className="flex flex-col justify-start">
                    <h3 className="text-sm md:text-md font-semibold">
                      Doctor: {appointment.doctor}
                    </h3>
                    <p className="text-sm font-semibold">
                      Motivo: {appointment.motivo}
                    </p>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssistantAppointmentList;
