import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "./ui/form"
import { useForm } from "react-hook-form";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Edit2 } from "lucide-react";
import { assistantSupabase } from "../supabaseClient";
import AssistantAppointmentList from "./AssistantAppointmentList";

interface EditUserDialogProps {
  user: any;
  appointments: any[];
  getUser: (phone: string) => void;
  getAppointments: () => void;
}

const EditUserDialog: React.FC<EditUserDialogProps> = ({
  user,
  appointments,
  getUser,
  getAppointments
}) => {

  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(user);
  const phoneForm = useForm({
    defaultValues: {
      phone: userData.phone
    }
  })
  const form = useForm({
    defaultValues: {
      nombre: userData.nombre,
      dni: userData.dni,
      fecha_nacimiento: userData.fecha_nacimiento,
    }
  });

  async function onSubmit(data: any) {
    try {
      if (userData.id) {
        // User exists, update data
        let info = userData.info;
        info['nombre'] = data.nombre;
        info['fecha_nacimiento'] = data.fecha_nacimiento;
        const { error } = await assistantSupabase
          .from("user")
          .update({
            info: info || userData.info,
            'DNI': data.dni || userData.dni,
          })
          .eq('id', userData.id)
          getUser(userData.phone);
        if (error) {
          throw error;
        }
      } else {
        // User does not exist, create new
        const info = {
          "nombre": data.nombre,
          "fecha_nacimiento": data.fecha_nacimiento,
          "seguro_medico": '',
          "numero_poliza_seguro": '',
          "historia_clinica": [],
          "citas_pasadas": [],
          "notas": [],
        }
        const { error } = await assistantSupabase
          .from("user")
          .insert({
            info: info,
            'DNI': data.dni,
            phone: phoneForm.getValues().phone,
          })
          getUser(phoneForm.getValues().phone);
        if (error) {
          throw error;
        }
      }
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function onPhoneChange(data: any) {
    const newUser = await getUser(data.phone);
    setUserData(newUser);
  }

  useEffect(() => {
    form.setValue("nombre", userData.nombre);
    form.setValue("dni", userData.dni);
    form.setValue("fecha_nacimiento", userData.fecha_nacimiento);
  }, [userData]);


  
  return (
    <Dialog open={open} onOpenChange={setOpen}>
    <DialogTrigger asChild>
      <Button className="hover:bg-transparent bg-transparent">
        <Edit2 className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer" />
      </Button>
    </DialogTrigger>
    <DialogContent className="sm:max-w-[950px] w-11/12 max-h-[90vh] overflow-y-auto">
      <DialogHeader>
        <DialogTitle className="text-2xl">Editar información del usuario</DialogTitle>
      </DialogHeader>
      <div className="flex gap-10">
        <div className="basis-1/2">
          <Form {...phoneForm}>
            <form
              onSubmit={phoneForm.handleSubmit(onPhoneChange)}
              className="flex flex-row gap-3 items-end"
            >

              <FormField
                control={phoneForm.control}
                name="phone"
                render={({ field }) => (
                  <FormItem className="basis-7/12">
                    <FormLabel>Teléfono</FormLabel>
                    <FormControl>
                      <Input {...field}/>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit" className="basis-5/12">Cambiar Teléfono</Button>
            </form>
          </Form>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grid gap-4"
            >

              <FormField
                control={form.control}
                name="nombre"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nombre</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="dni"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>DNI</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="fecha_nacimiento"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Fecha de nacimiento</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
                <Button type="submit">{userData.id ? 'Guardar Cambios' : 'Crear Usuario'}</Button>
              </form>
            </Form>
          </div>

        <div className="basis-1/2">
          <AssistantAppointmentList
            appointments={appointments}
            fetchAppointments={getAppointments}
            user={userData}
          ></AssistantAppointmentList>
        </div>
      </div>

    </DialogContent>
  </Dialog>
)};

export default EditUserDialog;