import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "./ui/form"
import { useForm } from "react-hook-form";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { TextArea } from "./ui/textarea";
import { Edit2 } from "lucide-react";
import { assistantSupabase } from "../supabaseClient";

interface EditCompanyDialogProps {
  company: any;
  getCompany: (id: number) => void;
}

const EditCompanyDialog: React.FC<EditCompanyDialogProps> = ({
  company,
  getCompany,
}) => {

  const [open, setOpen] = useState(false);
  const form = useForm({
    defaultValues: {
      prompt: company.additional_info,
      name: company.name
    }
  });

  async function onSubmit(data: any) {
    if (data.prompt && data.name) {
      try {
        const { error } = await assistantSupabase
          .from("company")
          .update({
            name: data.name,
            additional_info: data.prompt,
          })
          .eq('id', company.id)
          getCompany(company.id);
        if (error) {
          throw new Error("Error updating company information")
        }
        getCompany(company.id);
        setOpen(false);
      } catch (error) {
        console.error(error)
      }
    } else {
      if (!data.prompt) form.setError("prompt", {message: "Company information is required"});
      if (!data.name) form.setError("name", {message: "Company name is required"});
    }
  }

  useEffect(() => {
    form.setValue("name", company.name);
    form.setValue("prompt", company.additional_info);
  }, [company]);


  
  return (
    <Dialog open={open} onOpenChange={setOpen}>
    <DialogTrigger asChild>
      <Button className="hover:bg-transparent bg-transparent">
        <Edit2 className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer" />
      </Button>
    </DialogTrigger>
    <DialogContent className="sm:max-w-[950px] w-11/12 max-h-[90vh] overflow-y-auto">
      <DialogHeader>
        <DialogTitle className="text-2xl">Editar información de la empresa</DialogTitle>
      </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-4"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nombre</FormLabel>
                    <FormControl>
                      <Input {...field}/>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="prompt"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Información</FormLabel>
                    <FormControl>
                      <TextArea rows={15} {...field}/>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Guardar Cambios</Button>
            </form>
          </Form>

    </DialogContent>
  </Dialog>
)};

export default EditCompanyDialog;