/** @format */

import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { useTranslation } from "react-i18next";
import { useSession } from "../context/SessionContext";
import { useNavigate } from "react-router-dom";

interface ClientListProps {
  veterinarianId: string;
}

export function ClientList({ veterinarianId }: ClientListProps) {
  const { t } = useTranslation();

  const [clients, setClients] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState<any | null>(null);
  const { setActivePhone, addRecentUser, session, refreshTokenIfNeeded, userInfo } =
    useSession();
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
  }, [veterinarianId]);

  const fetchClients = async () => {
    refreshTokenIfNeeded();
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/veterinarians/id/${session?.user.id}/clients`,
      {
        method: "GET",
        headers: {
          Authorization: `${session!.token_type} ${session!.access_token}`,
        },
      }
    );
    const data = await response.json();
    setClients(data);

  };

  const handleOpenChat = (client: any) => {
    setActivePhone(client.phone);
    addRecentUser(client);
    navigate("/");
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("my_clients")}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {clients.map((client) => (
            <Card key={client.id} className="flex flex-col">
              <CardContent className="flex items-center p-4">
                <div className="flex-1">
                  <h3 className="text-lg font-semibold">
                    {client.user.name}
                  </h3>
                </div>
                <Dialog>
                  <div className="flex gap-2">
                    <DialogTrigger asChild>
                      <Button
                        variant="outline"
                        onClick={() => setSelectedClient(client)}
                      >
                        {t("view_details")}
                      </Button>
                    </DialogTrigger>
                    <DialogTrigger asChild>
                      <Button
                        variant="default"
                        onClick={() => handleOpenChat(client)}
                      >
                        {t("open_chat")}
                      </Button>
                    </DialogTrigger>
                  </div>
                  <DialogContent
                    className="max-w-3xl max-h-[80vh] overflow-y-auto"
                    aria-describedby="client-info-description"
                  >
                    <DialogHeader>
                      <DialogTitle>
                        {t("client_information", {
                          clientName: client.user.name,
                        })}
                      </DialogTitle>
                    </DialogHeader>
                    <p id="client-info-description" className="sr-only">
                      {t("client_description", {
                        clientName: client.user.name,
                        petName: "client.info.mascota.nombre",
                      })}
                    </p>
                    {selectedClient && (
                      <div className="space-y-4">
                        <div>
                          <h3 className="text-lg font-semibold">
                            {t("owner_information")}
                          </h3>
                          <p>
                            <strong>{t("name")}:</strong>{" "}
                            {selectedClient.user.name}
                          </p>
                          <p>
                            <strong>{t("phone")}:</strong>{" "}
                            {selectedClient.user.phone}
                          </p>
                        </div>
                        {userInfo.role.id === 1 && (
                          <>
                            <div>
                              <h3 className="text-lg font-semibold">
                                {t("pet_information")}
                              </h3>
                              <p>
                                <strong>{t("pet_name")}:</strong>{" "}
                                {selectedClient.client_pet[0].pet.name}
                              </p>
                              <p>
                                <strong>{t("species")}:</strong>{" "}
                                {selectedClient.client_pet[0].pet.species}
                              </p>
                              <p>
                                <strong>{t("breed")}:</strong>{" "}
                                {selectedClient.client_pet[0].pet.breed}
                              </p>
                              <p>
                                <strong>{t("age")}:</strong>{" "}
                                {(() => {
                                  const birthDate = new Date(selectedClient.client_pet[0].pet.birth_date);
                                  const now = new Date();
                                  let age = now.getFullYear() - birthDate.getFullYear();
                                  const monthDiff = now.getMonth() - birthDate.getMonth();

                                  if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birthDate.getDate())) {
                                    age--;
                                  }

                                  return age;
                                })()}{" "}
                                {t("pet_years")}
                              </p>

                              <p>
                                <strong>{t("weight")}:</strong>{" "}
                                {selectedClient.client_pet[0].pet.weight} kg
                              </p>
                              <p>
                                <strong>{t("sterilized")}:</strong>{" "}
                                {selectedClient.client_pet[0].pet.is_neutered
                                  ? t("yes")
                                  : t("no")}
                              </p>
                            </div>
                                  {/** 
                            <div>
                              <h3 className="text-lg font-semibold">
                                {t("vaccines")}
                              </h3>
                              <h4 className="font-semibold">
                                {t("administered_vaccines")}:
                              </h4>
                        
                              <ul className="list-disc list-inside">
                                {selectedClient.info.mascota.vacunas ? (
                                  selectedClient.info.mascota.vacunas.map(
                                    (vaccine: { vaccine_name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; administered_date: string | number | Date; }, index: React.Key | null | undefined) => (
                                      <li key={index}>
                                        {vaccine.vaccine_name} -{" "}
                                        {new Date(
                                          vaccine.administered_date
                                        ).toLocaleDateString()}
                                      </li>
                                    )
                                  )
                                ) : (
                                  <li>{t("no_vaccines_administered")}</li>
                                )}
                              </ul>
                              <h4 className="font-semibold mt-2">
                                {t("upcoming_vaccines")}:
                              </h4>
                              <ul className="list-disc list-inside">
                                {selectedClient.info.mascota.proximo_calendario_vacunacion.map(
                                  (vaccine: { vaccine_name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; next_due_date: string | number | Date; }, index: React.Key | null | undefined) => (
                                    <li key={index}>
                                      {vaccine.vaccine_name} -{" "}
                                      {new Date(
                                        vaccine.next_due_date
                                      ).toLocaleDateString()}
                                    </li>
                                  )
                                )}
                              </ul>
                              <h4 className="font-semibold mt-2">
                                {t("pending_vaccines")}:
                              </h4>
                              <ul className="list-disc list-inside">
                                {selectedClient.info.mascota.vacunas_faltantes
                                  .length > 0 ? (
                                  selectedClient.info.mascota.vacunas_faltantes.map(
                                    (vaccine: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined, index: React.Key | null | undefined) => (
                                      <li key={index}>{vaccine}</li>
                                    )
                                  )
                                ) : (
                                  <li>{t("no_pending_vaccines")}</li>
                                )}
                              </ul>
                            </div>
                            <div>
                              <h3 className="text-lg font-semibold">
                                {t("follow_up_history")}
                              </h3>
                              <ul className="space-y-2">
                                {selectedClient.info.mascota.follow_up.map(
                                  (entry: { fecha_visita: string | number | Date; comentario: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }, index: React.Key | null | undefined) => (
                                    <li key={index} className="border-b pb-2">
                                      <p>
                                        <strong>{t("date")}:</strong>{" "}
                                        {new Date(
                                          entry.fecha_visita
                                        ).toLocaleDateString()}
                                      </p>
                                      <p>
                                        <strong>{t("comment")}:</strong>{" "}
                                        {entry.comentario}
                                      </p>
                                    </li>
                                  )
                                )}
                              </ul>
                                  
                            </div>
   */}
                          </>
                        )}
                      </div>
                    )}
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
