import * as React from "react"

import { cn } from "../../lib/utils"

type TextAreaElement = React.ElementRef<'textarea'>;

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const TextArea = React.forwardRef<TextAreaElement, TextAreaProps>(
  ({ className, rows, ...props }) => {
    return (
        <textarea
          className={cn(
            "flex h-50 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          rows={rows}
          {...props}
        />
    )
  }
)
TextArea.displayName = "TextArea"

export { TextArea }