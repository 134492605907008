/** @format */

import React from "react";
import { CalendarIcon, Clock, Info, Edit2 } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import EditAppointmentForm from "./EditAppointmentForm";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";


interface AppointmentListProps {
  confirmedAppointments: any[];
  t: (key: string) => string;
  fetchConfirmedAppointments: () => void;
  userInfo: any;
}

const AppointmentList: React.FC<AppointmentListProps> = ({
  confirmedAppointments,
  t,
  fetchConfirmedAppointments,
  userInfo,
}) => {
  return (
    <div>
      {confirmedAppointments && confirmedAppointments.length > 0 && (
        <div>
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
            {t("confirmed_appointments")}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {confirmedAppointments.map((appointment) => (
              <Card key={appointment.id} className="p-4">
                <CardHeader className="flex flex-row items-center gap-2 p-2">
                  <CalendarIcon className="text-green-600 w-5 h-5" />
                  <CardTitle className="text-sm md:text-md">
                    {new Date(appointment.date).toLocaleDateString()}
                  </CardTitle>
                  <Clock className="text-green-600 w-5 h-5 ml-auto" />
                  <span className="text-sm">
                    {new Date(appointment.date).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                  <Popover>
                    <PopoverTrigger asChild>
                      <button className="ml-2">
                        <Edit2 className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer" />
                      </button>
                    </PopoverTrigger>
                    <PopoverContent className="p-4 bg-white rounded-lg shadow-lg w-[calc(100vw-2rem)] max-w-md mx-auto left-0 right-0">
                      <EditAppointmentForm
                        appointment={appointment}
                        onUpdate={fetchConfirmedAppointments}
                        veterinarian={userInfo}
                      />
                    </PopoverContent>
                  </Popover>
                  {userInfo.role.id === 1 &&
                    appointment.client_veterinarian.client.client_pet[0].pet && (
                      <Popover>
                        <PopoverTrigger asChild>
                          <button className="ml-2">
                            <Info className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer" />
                          </button>
                        </PopoverTrigger>
                        <PopoverContent className="p-4 bg-white rounded-lg shadow-lg w-[calc(100vw-2rem)] max-w-md mx-auto left-0 right-0">
                          <h3 className="text-lg font-semibold mb-4">
                            {t("pet_details")}
                          </h3>
                          <div className="space-y-4 text-sm">
                            <div className="grid grid-cols-2 gap-2">
                              <p>
                                <span className="font-semibold">
                                  {t("pet_name")}:
                                </span>{" "}
                                {appointment.client_veterinarian.client.client_pet[0].pet.name}
                              </p>
                              <p>
                                <span className="font-semibold">
                                  {t("pet_species")}:
                                </span>{" "}
                                {appointment.client_veterinarian.client.client_pet[0].pet.breed}
                              </p>
                              <p>
                                <span className="font-semibold">
                                  {t("pet_breed")}:
                                </span>{" "}
                                {appointment.client_veterinarian.client.client_pet[0].pet.species}
                              </p>
                              <p>
                                <span className="font-semibold">
                                  {t("pet_age")}:
                                </span>{" "}

                                {(() => {
                                  const birthDate = new Date(appointment.client_veterinarian.client.client_pet[0].pet.birth_date);
                                  const now = new Date();
                                  let age = now.getFullYear() - birthDate.getFullYear();
                                  const monthDiff = now.getMonth() - birthDate.getMonth();

                                  if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birthDate.getDate())) {
                                    age--;
                                  }

                                  return age;
                                })()}{" "}
                                {t("pet_years")}
                              </p>
                              <p>
                                <span className="font-semibold">
                                  {t("pet_weight")}:
                                </span>{" "}
                                {appointment.client_veterinarian.client.client_pet[0].pet.weight} kg
                              </p>
                              <p>
                                <span className="font-semibold">
                                  {t("pet_sterilized")}:
                                </span>{" "}
                                {appointment.client_veterinarian.client.client_pet[0].pet.is_neutered
                                  ? t("yes")
                                  : t("no")}
                              </p>
                            </div>
                          </div>
                        </PopoverContent>
                      </Popover>
                    )}
                </CardHeader>
                <CardContent className="p-2">
                  <div className="flex flex-col justify-start">
                    <h3 className="text-sm md:text-md font-semibold">
                      {t("name")}:
                      {appointment?.client_veterinarian?.client?.user?.name ?? "Sin nombre"}
                    </h3>
                    <p className="text-sm font-semibold">
                      {t("reason")}:
                      {appointment?.reason ?? "Sin motivo"}
                    </p>
                  </div>
                </CardContent>

              </Card>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentList;
