import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { assistantSupabase } from "../supabaseClient";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import es from 'date-fns/locale/es';
import EditUserDialog from "./EditUserDialog";
import EditCompanyDialog from "./EditCompanyDialog";

const emptyUser = {
    id: null,
    nombre: '',
    dni: '',
    phone: '',
    fecha_nacimiento: '',
    seguro_medico: null,
    numero_poliza_seguro: null,
    historia_clinica: [],
    recomendaciones: [],
    citas_pasadas: [],
    info: {}
}

const emptyCompany = {
    id: null,
    name: '',
    additional_info: '',
}


const ChatAssistant = ({ isVeterinarian, userPhone, setUserPhone }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("skills");
    const [companyData, setCompanyData] = useState(emptyCompany);
    const [userData, setUserData] = useState(emptyUser);
    const [userAppointments, setUserAppointments] = useState([]);

    async function getUserData(phone) {
        try {
            let { data } = await assistantSupabase.from("user").select("*").eq("phone", phone).limit(1);
            if (data) {
                data = {
                    id: data[0]['id'],
                    nombre: data[0]['info']['nombre'],
                    dni: data[0]['DNI'],
                    phone: data[0]['phone'],
                    fecha_nacimiento: data[0]['info']['fecha_nacimiento'],
                    seguro_medico: data[0]['info']['seguro_medico'],
                    numero_poliza_seguro: data[0]['info']['numero_poliza_seguro'],
                    historia_clinica: data[0]['info']['historia_clinica'],
                    recomendaciones: data[0]['info']['notas'],
                    citas_pasadas: data[0]['info']['citas_pasadas'],
                    info: data[0]['info']
                }
                setUserData(data);
                setUserPhone(data.phone);
                return data;
            } else {
                throw new Error('User not found');
            }
        } catch (e) {
            setUserData({...emptyUser, phone});
            setUserPhone(phone);
            return emptyUser;
        }
    }

    async function getUserAppointments() {
        try {
            if (userData.id) {
                let { data } = await assistantSupabase.from("appointment").select("*, veterinarian(id, name, vet_specialties(specialty_name))").eq("user_id", userData.id).order("fecha", "asc");
                if (data) {
                    data = data.filter(a => new Date(a.fecha) >= new Date()).map(d => {
                        return {
                            id: d.id,
                            fecha: d.fecha,
                            fechaText: format(toZonedTime(d.fecha, "UTC"), "dd 'de' MMMM h:mm'h'", { locale: es }),
                            doctor: d.veterinarian.name,
                            doctor_id: d.veterinarian.id,
                            motivo: d.motivo,
                            especialidad: d.veterinarian.vet_specialties.specialty_name.toLowerCase()
                        }
                    })
                }
                setUserAppointments(data);
            } else {
                throw new Error('User ID is undefined or null.');
            }
        } catch (e) {
            setUserAppointments([]);
        }
    }

    async function getCompanyData(id) {
        try {
            let { data } = await assistantSupabase.from("company").select("*").eq("id", id).limit(1);
            if (data) {
                data = {
                    id: data[0]['id'],
                    name: data[0]['name'],
                    additional_info: data[0]['additional_info'],
                }
                setCompanyData(data);
            } else {
                throw new Error('Company not found');
            }
        } catch (e) {
            setCompanyData(emptyCompany);
        }
    }

    useEffect(() => {
        getUserData(userPhone);
        getCompanyData(process.env.REACT_APP_COMPANY_ID);
      }, [userPhone]);

      useEffect(() => {
        getUserAppointments();
      }, [userData]);

    return (
        <div className="w-2/4 flex flex-col justify-center items-center">
            <div className="flex w-full">
                <button
                    onClick={() => setActiveTab("skills")}
                    className={`flex-1 py-2 text-center font-semibold rounded-tl-sm ${activeTab === "skills" ? "bg-[#34a4d0] text-white" : "bg-gray-200 text-gray-700"
                        }`}
                >
                    {t("skills_tab_title")}
                </button>
                <button
                    onClick={() => setActiveTab("client")}
                    className={`flex-1 py-2 text-center font-semibold rounded-tr-sm ${activeTab === "client" ? "bg-[#34a4d0] text-white" : "bg-gray-200 text-gray-700"
                        }`}
                >
                    {t("client_info_tab_title")}
                </button>
            </div>
            <div className="bg-white px-8 py-6 rounded-sm w-full">
                {activeTab === "skills" ? (
                    <>
                        <h2 className="text-3xl font-bold text-[#34a4d0] mb-4">{t("sofia_skills_title")}</h2>
                        <div className="mb-4">
                            <h3 className="text-md font-semibold text-gray-900">{t("intelligent_booking")}</h3>
                            <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                <li>{t("skills_manage_appointments")}</li>
                            </ul>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-md font-semibold text-gray-900">{t("frequent_questions")}</h3>
                            <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                <li>{t("skills_answer_clinic_info")}</li>
                            </ul>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-md font-semibold text-gray-900">WHATSAPP</h3>
                            <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                <li>{t("skills_whatsapp_messages")}</li>
                            </ul>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-md font-semibold text-gray-900">{t("security_privacy")}</h3>
                            <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                <li>{t("skills_no_sensitive_info")}</li>
                            </ul>
                        </div>
                        <EditCompanyDialog
                            company={companyData}
                            getCompany={getCompanyData}
                        ></EditCompanyDialog>
                    </>
                ) : (
                    <>
                        {isVeterinarian ? (
                            <>
                                <h2 className="text-2xl font-bold text-[#34a4d0] mb-4">
                                    {t("client_info_title", { clientName: userData.nombre })}
                                </h2>
                                <div className="mb-2">
                                    <h3 className="text-lg font-semibold text-gray-900">{t("owner_information_title")}</h3>
                                    <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                        <li>{t("owner_phone", { phone: userData['phone'] || '' })}</li>
                                    </ul>
                                </div>
                                <div className="mb-2">
                                    <h3 className="text-lg font-semibold text-gray-900">{t("pet_information_title")}</h3>
                                    <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                        <li>{t("pet_name", { name: "Congo" })}</li>
                                        <li>{t("pet_breed", { breed: "Caniche" })}</li>
                                        <li>{t("pet_sex", { sex: "Macho" })}</li>
                                        <li>{t("pet_age", { age: "13 años y 6 meses" })}</li>
                                        <li>{t("pet_passport_number", { passport: "ES012929737" })}</li>
                                        <li>{t("pet_microchip", { microchip: "941000022590412" })}</li>
                                        <li>{t("pet_hospitalized", { hospitalized: "No" })}</li>
                                    </ul>
                                </div>
                                <div className="mb-2">
                                    <h3 className="text-lg font-semibold text-gray-900">{t("pet_history_title")}</h3>
                                    <p className="text-sm text-gray-700 ml-4">{t("pet_history_description")}</p>
                                </div>
                                <div className="mb-2">
                                    <h3 className="text-lg font-semibold text-gray-900">{t("past_appointments_title")}</h3>
                                    <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                        <li>{t("past_appointment_1")}</li>
                                        <li>{t("past_appointment_2")}</li>
                                        <li>{t("past_appointment_3")}</li>
                                        <li>{t("past_appointment_4")}</li>
                                        <li>{t("past_appointment_5")}</li>
                                        <li>{t("past_appointment_6")}</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-900">{t("upcoming_appointments_title")}</h3>
                                    <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                        <li>{t("upcoming_appointment_1")}</li>
                                        <li>{t("upcoming_appointment_2")}</li>
                                    </ul>
                                </div>
                            </>
                        ) : (
                            <>
                                <h2 className="text-2xl font-bold text-[#34a4d0] mb-4">
                                    {t("client_info_title", { clientName: userData.nombre })}
                                </h2>
                                <div className="grid grid-cols-2 gap-x-6 gap-y-2 text-gray-700 text-sm mb-6">
                                    <p>
                                        <strong>{t("client_dni")}:</strong> {userData.dni}
                                    </p>
                                    <p>
                                        <strong>{t("client_phone")}:</strong> {userData.phone}
                                    </p>
                                    <p>
                                        <strong>{t("client_birthdate")}:</strong> {userData.fecha_nacimiento}
                                    </p>
                                    <p>
                                        <strong>{t("client_insurance")}:</strong> {userData.seguro_medico}
                                    </p>
                                    <p>
                                        <strong>{t("insurance_policy")}:</strong> {userData.numero_poliza_seguro}
                                    </p>
                                </div>
                                <div className="mb-6">
                                    <h3 className="text-lg font-semibold text-gray-900">{t("medical_history")}</h3>
                                    <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                        {userData.historia_clinica.map((historia) => (
                                            <li key={historia} style={{whiteSpace: 'pre-line'}}>{historia}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="mb-6">
                                    <h3 className="text-lg font-semibold text-gray-900">{t("past_appointments_title")}</h3>
                                    <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                        {userData.citas_pasadas.map((cita) => (
                                            <li key={cita} style={{whiteSpace: 'pre-line'}}>{cita}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="mb-6">
                                    <h3 className="text-lg font-semibold text-gray-900">{t("upcoming_appointments")}</h3>
                                    <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                        {userAppointments.map((cita) => (
                                            <li key={cita.fecha}>{cita.fechaText}: Cita en {cita.especialidad} con {cita.doctor}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-900">{t("next_recommendation")}</h3>
                                    <ul className="list-disc list-inside text-sm text-gray-700 ml-4">
                                        {userData.recomendaciones.map((rec) => (
                                            <li key={rec} style={{whiteSpace: 'pre-line'}}>{rec}</li>
                                        ))}
                                    </ul>
                                </div>
                                <EditUserDialog user={userData} appointments={userAppointments} getUser={getUserData} getAppointments={getUserAppointments}></EditUserDialog>
                            </>
                        )}

                    </>
                )}
            </div>
        </div>
    );
};

export default ChatAssistant;
