import React from 'react';
import { format } from 'date-fns';
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Button } from "./ui/button";

interface Appointment {
  id: number;
  fecha: string;
  estado: string;
}

interface PopoverAppointmentProps {
  date: Date;
  appointments: any[];
}

export const PopoverAppointment: React.FC<PopoverAppointmentProps> = ({ date, appointments }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          className="h-8 w-8 p-0 font-normal bg-green-100 hover:bg-green-200 rounded-full"
        >
          <time dateTime={format(date, 'yyyy-MM-dd')}>
            {date.getDate()}
          </time>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <div className="space-y-2">
          <h3 className="font-semibold">{format(date, 'MMMM d, yyyy')}</h3>
          {appointments.length !== 0 && appointments.map((appointment) => (
            
            <div key={appointment.id} className="flex justify-between items-center">
            <span>
              {appointment?.date ? format(new Date(appointment.date), 'HH:mm') : ''}
            </span>
            <span className="capitalize">
              {
                appointment?.client_veterinarian?.client?.user?.name 
                  ? appointment.client_veterinarian.client.user.name 
                  : 'Undefined'
              }
              {' - '}
              {
                appointment?.client_veterinarian?.client?.client_pet?.[0]?.pet?.name 
                  ? appointment.client_veterinarian.client.client_pet[0].pet.name 
                  : 'Undefined'
              }
            </span>
          </div>
          
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};