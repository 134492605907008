/** @format */

import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";
import { CalendarIcon } from "lucide-react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { useSession } from "../context/SessionContext";
import { useTranslation } from "react-i18next";

const FormSchema = z.object({
  appointmentDate: z.date({
    required_error: "An appointment date is required.",
  }),
  appointmentTime: z.string().optional(),
});

export default function EditAppointmentForm({
  appointment,
  onUpdate,
  veterinarian,
}: any) {
  const { t } = useTranslation();
  const { session, refreshTokenIfNeeded } = useSession();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      appointmentDate: new Date(appointment.date),
      appointmentTime: format(new Date(appointment.date), "HH:mm"),
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [availableTimes, setAvailableTimes] = useState<string[]>([]);

  useEffect(() => {
    fetchAvailableDates();
  }, [veterinarian]);

  useEffect(() => {
    fetchAvailableTimes(new Date(appointment.date));
  }, [appointment.date, veterinarian]);

  const allPossibleTimes = [];
  for (let hour = 9; hour <= 20; hour++) {
    // Formatear la hora para que siempre tenga 2 dígitos (ej. "09:00", "10:00")
    const formattedHour = hour.toString().padStart(2, "0") + ":00";
    allPossibleTimes.push(formattedHour);
  }

  const fetchAvailableDates = async () => {
    refreshTokenIfNeeded();
    const response: any = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/appointments/${session?.user.id}`,
      {
        method: "GET",
        headers: {
          Authorization: `${session!.token_type} ${session!.access_token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    const uniqueDates = Array.from(
      new Set(
        data.map((appointment: any) =>
          new Date(appointment.date).toDateString()
        )
      )
    ).map((dateString: any) => new Date(dateString));

    setAvailableDates(uniqueDates);
  };

  const fetchAvailableTimes = async (date: any) => {
    refreshTokenIfNeeded();
    const response: any = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/appointments/${session?.user.id}`,
      {
        method: "GET",
        headers: {
          Authorization: `${session!.token_type} ${session!.access_token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    const times = data
      .map((appointment: any) =>
        format(toZonedTime(appointment.date, "UTC"), "HH:mm")
      )
      .sort();

    setAvailableTimes(Array.from(new Set(times))); // Ensure unique times
  };


  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    const { appointmentDate, appointmentTime } = data;
    if (!appointmentTime) return;

    const [hours, minutes] = appointmentTime.split(":");

    const newAppointmentDateTimeUTC = new Date(
      Date.UTC(
        appointmentDate.getFullYear(),
        appointmentDate.getMonth(),
        appointmentDate.getDate(),
        parseInt(hours, 10),
        parseInt(minutes, 10)
      )
    );
    try {
      refreshTokenIfNeeded();
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/appointments/${appointment.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/appointments/`,
        {
          method: "POST",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            client_id: appointment.client_veterinarian.client_id,
            veterinarian_id: appointment.client_veterinarian.veterinarian_id,
            reason: appointment.reason,
            isConfirmed: true,
            date: newAppointmentDateTimeUTC,
            sid: "example",
          }),
        }
      );

      onUpdate();
    } catch (error) {
      console.error(t("error_update"), error);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex flex-col space-y-4">
          {/* Date Selection */}
          <FormField
            control={form.control}
            name="appointmentDate"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>{t("new_appointment_date")}</FormLabel>
                <Popover open={isOpen} onOpenChange={setIsOpen}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button variant="outline" className="w-full text-left">
                        {field.value
                          ? format(field.value, "PPP")
                          : t("pick_date")}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={(date) => {
                        field.onChange(date);
                        setIsOpen(false);
                        if (date) {
                          fetchAvailableTimes(date);
                        }
                      }}
                      disabled={(date) => date.getDay() === 0}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Time Selection */}
          <FormField
            control={form.control}
            name="appointmentTime"
            render={({ field }) => {
              // Generar las horas disponibles de 9 a 20
              const allPossibleTimes = [];
              for (let hour = 9; hour <= 20; hour++) {
                const formattedHour = hour.toString().padStart(2, "0") + ":00";
                allPossibleTimes.push(formattedHour);
              }

              // Filtrar las horas ya reservadas (availableTimes)
              // Observa que availableTimes pueden traer minutos diferentes, 
              // pero si la hora coincide (ej. "16:58") queremos excluir "16:00".
              // En ese caso, primero extraemos la hora de las reservas:
              const reservedHours = availableTimes.map((time) => {
                // time es algo como "16:58", extraemos solo las horas "16:00"
                // Suponiendo que quieres hacer coincidir cualquier minuto dentro de esa hora.
                const hourPart = time.split(":")[0];
                return hourPart.padStart(2, "0") + ":00";
              });

              const freeTimes = allPossibleTimes.filter(
                (time) => !reservedHours.includes(time)
              );

              return (
                <FormItem>
                  <FormLabel>{t("appointment_time")}</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={t("select_time")} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {freeTimes.length > 0 ? (
                        freeTimes.map((time) => (
                          <SelectItem key={time} value={time}>
                            {time}
                          </SelectItem>
                        ))
                      ) : (
                        <SelectItem disabled value="no-available-times">
                          {t("no_available_time")}
                        </SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                </FormItem>
              );
            }}
          />
        </div>

        <Button type="submit" className="w-full">
          {t("save_changes")}
        </Button>
      </form>
    </Form>
  );
}
