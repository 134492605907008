/** @format */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { User } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Button } from "../components/ui/button";
import { useSession } from "../context/SessionContext";
import { supabase } from "../supabaseClient";
import { useTranslation } from "react-i18next";

const Navbar = ({
  onUserSelect,
}: {
  onUserSelect: (phone: string) => void;
}) => {
  const { t } = useTranslation();
  const { user, userInfo } = useSession();
  const [companyLogoUrl, setCompanyLogoUrl] = useState<string | null>(null);
  const navigate = useNavigate();
  const isVeterinarian = process.env.REACT_APP_ENV === "veterinarian"

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (!error) {
        navigate("/login", { replace: true });
      }
    } catch (err) {
      console.error("Error during sign-out:", err);
    }
  };

  useEffect(() => {
    const fetchCompanyLogo = async () => {
      if (!userInfo?.clinica_id) return;

      try {
        const folderName = userInfo.clinica_id;

        const { data: listData, error: listError } = await supabase.storage
          .from("company_logo")
          .list(folderName);

        if (listError) {
          console.error("Error checking company logo folder:", listError);
          return;
        }

        if (listData && listData.length > 0) {
          const filePath = `${folderName}/${listData[0].name}`;
          const { data } = supabase.storage
            .from("company_logo")
            .getPublicUrl(filePath);

          if (!data) {
            console.error("Error fetching company logo URL:");
          } else if (data) {
            setCompanyLogoUrl(data.publicUrl);
          }
        } else {
          console.log("No logo found in folder:", folderName);
        }
      } catch (error) {
        console.error("Error fetching company logo:", error);
      }
    };

    fetchCompanyLogo();
  }, [userInfo?.clinica_id]);

  if (!user) return null;

  return (
    <nav className="bg-[#f6f7f7] border-b">
      <div className="w-auto mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <Link
              to="/"
              className="flex items-center text-2xl font-bold text-primary"
            >
              {!isVeterinarian ? (
                <img
                  src={"../sina_suite_logo.svg"}
                  alt="Company Logo"
                  className="h-10 w-auto mr-2"
                />
              ) : <img
                src={"../faviconVet.svg"}
                alt="Company Logo"
                className="h-10 w-auto mr-2"
              />}
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            {/* User Menu */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <User className="h-5 w-5" />
                  <span className="sr-only">{t("open_user_menu")}</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem asChild>
                  <Link to="/profile">{t("profile")}</Link>
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                  <Link to="/voice-app">{t("voice_app")}</Link>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleSignOut}>
                  {t("sign_out")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
