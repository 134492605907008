import React, { useCallback, useEffect, useState } from 'react';
import { useSession } from '../context/SessionContext';
import { AnimatePresence, motion } from "framer-motion";
import {
  LiveKitRoom,
  useVoiceAssistant,
  BarVisualizer,
  RoomAudioRenderer,
  DisconnectButton,
  useConnectionState
} from "@livekit/components-react";
import { useRoomInfo, useRoomContext, useParticipants } from "@livekit/components-react";
import { NoAgentNotification } from '../components/NoAgentNotification';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Phone, X, Mic } from "lucide-react";
import ChatAssistant from '../components/ChatAssistant';

const ParticipantForm = ({ onSubmit, onCancel, setClientInfo }) => {
  const { t } = useTranslation();
  const { userInfo } = useSession();
  const [name, setName] = useState(userInfo.name);
  const [assistant, setAssistant] = useState("demo");
  const [clientInfo, setLocalClientInfo] = useState("");

  const handleClientInfoUpdate = () => {
    if (clientInfo) {
      setClientInfo(clientInfo);
    }
  };

  return (
    <div className="bg-black p-6 rounded-lg shadow-lg max-w-md w-full">
      <div className="space-y-4">
        <div>
          <label className="block text-sm mb-1">{t('form_client_info_label')}</label>
          <textarea
            value={clientInfo}
            onChange={(e) => setLocalClientInfo(e.target.value)}
            onBlur={handleClientInfoUpdate}
            className="w-full px-3 py-2 bg-white/10 rounded-md h-24"
            placeholder={t('form_client_info_placeholder')}
          />
        </div>
        <div className="flex gap-3">
          <button
            onClick={() => onSubmit({ name, assistant })}
            disabled={!name.trim() || !assistant.trim()}
            className="flex-1 uppercase px-4 py-2 bg-white text-black rounded-md disabled:opacity-50"
          >
            {t('form_start_button')}
          </button>
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-white/10 rounded-md"
          >
            {t('form_cancel_button')}
          </button>
        </div>
      </div>
    </div>
  );
};
const TextArea = ({ value, onChange, onSave }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-4">
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full h-[calc(100vh-600px)] px-3 py-2 text-gray-100 border rounded-lg focus:outline-none bg-gray-800"
        placeholder={t('textarea_placeholder')}
      />
      <button
        onClick={onSave}
        className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
      >
        {t('textarea_save_button')}
      </button>
    </div>
  );
};

export default function Settings() {
  const { t } = useTranslation();
  const clientVetInfo = "INFORMACIÓN DEL CLIENTE: Dueño: Mar Pujadas. Teléfono del dueño: 665852867. Mascota: Nombre: Congo Raza: Caniche. Sexo: Macho. Edad: 13 años y 6 meses. Número de pasaporte de la mascota: ES012929737. Microchip: 941000022590412. Actualmente hospitalizado: No. Historial: Castrado en 2023 en el centro. Sigue las pautas de desparasitación interna y externa, así como las recomendaciones de peluquería proporcionadas por el hospital. Citas pasadas: 10 de octubre de 2023 con Sofía Gonzalez 10:00 am: Vacunación anual (Rabia y polivalente). 20 de septiembre de 2023 con Marta Chavez 12:00 pm: Consulta dermatológica por enrojecimiento en el lomo y rascado excesivo. Diagnóstico: dermatitis alérgica, tratada con champú medicado y antihistamínicos. 5 de julio de 2023 con Marta Chavez 11:30 am: Control dermatológico para evaluar mejora tras tratamiento previo. Se añadió un suplemento de ácidos grasos y se recomendó evitar ciertos alimentos. 20 de febrero de 2023 Sofía Gonzalez 9:30 am: Desparasitación interna y externa. 10 de enero de 2023 Jorge Ávila 9:00 am: Castración. Procedimiento realizado en el centro, con recuperación sin incidencias. Se recomendaron controles postquirúrgicos y manejo del peso tras la intervención. 20 de marzo de 2022 con Marta Chavez 10:00 am: Consulta dermatológica por picores recurrentes. Se diagnosticó una infección por Malassezia, tratada con antimicóticos tópicos. Próximas citas: El 21 de diciembre a las 11 de la mañana en peluquería. Viernes 9 de febrero de 2024 a las 11:30 am con la doctora Sofia Gonzalez (su veterinaria habitual) para la aplicación de la vacuna de leishmaniosis (años anteriores se administraba la vacuna en otro centro)."
  const clienteHospInfo = "CLIENTE: Mar Pujadas.DNI: 22553481B.Teléfono: 670362038.Fecha de nacimiento: 2 de junio de 1965.Seguro médico: Sanitas Número de póliza 839110113 1 000234 017.Historia clínica:Hipertensión.Operado de anginas.Alergia a 17-BETA ESTRADIOL MICRONIZADO.Tornillo en el escafoides izquierdo.Citas Pasadas:12/03/2014: Extracción de cinco pólipos.18/07/2018: Extracción de un pólipo.05/09/2023: Extracción de dos pólipos pequeños.10/12/2024: Cita para control de hipertensión.Se requiere análisis de sangre para evaluar función renal y electrolitos.Próximas Citas:24/02/2025: Cita de dermatología con Carlos Perez el viernes 20 de diciembre a las 10h. Cita con dermatología con Andrea Gonzalez.Próxima Recomendación:Nueva colonoscopia recomendada en febrero de 2025."
  const { user, userInfo, loading, session, refreshTokenIfNeeded, language } = useSession();

  const isVeterinarian = process.env.REACT_APP_ENV === "veterinarian"


  const [connectionDetails, updateConnectionDetails] = useState(undefined);
  const [agentState, setAgentState] = useState("disconnected");
  const [showForm, setShowForm] = useState(false);
  const [participantInfo, setParticipantInfo] = useState(null);
  const [activeTab, setActiveTab] = useState('assistant');
  const [textAreaContent, setTextAreaContent] = useState('');
  const [companyType, setCompanyType] = useState(isVeterinarian ? "veterinary" : "hospital")
  const [clientInfo, setClientInfo] = useState(isVeterinarian ? clientVetInfo : clienteHospInfo)
  const [isConnected, setIsConnected] = useState(false);
  const [callType, setCallType] = useState("outgoing")
  const [selectedIcon, setSelectedIcon] = useState("outgoing");
  const [userPhone, setUserPhone] = useState("+34693793361"); // demo user

  const handleIconClick = (type) => {
    setSelectedIcon(type);
    setCallType(type)
  };
  const handleFormSubmit = useCallback((formData) => {
    setShowForm(false);
    localStorage.setItem('participantInfo', JSON.stringify(formData));
  }, []);

  const onConnectButtonClicked = useCallback(async () => {
    try {
      const participantInfo = JSON.parse(localStorage.getItem('participantInfo')) || {};
      const roomName = `voice_assistant_room_${Math.floor(Math.random() * 10_000)}`;
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/livekit/token`);

      url.searchParams.append("participantName", userInfo ? userInfo.name : "Anonymous");
      url.searchParams.append("roomName", roomName);
      if (participantInfo.context) {
        url.searchParams.append("context", participantInfo.context);
      }
      if (participantInfo.systemPrompt) {
        url.searchParams.append("systemPrompt", participantInfo.systemPrompt);
      }
      if (language) {
        url.searchParams.append("language", language);
      }
      url.searchParams.append("assistant", "demo");
      if (companyType) {
        url.searchParams.append("companyType", companyType);
      }
      if (clientInfo) {
        url.searchParams.append("clientInfo", clientInfo);
      }
      url.searchParams.append("callType", callType);
      if (userPhone) {
        url.searchParams.append("userPhone", userPhone);
      }

      const response = await fetch(url.toString(), { method: "GET" });
      if (!response.ok) throw new Error("Failed to fetch connection details");

      const connectionDetailsData = await response.json();
      updateConnectionDetails(connectionDetailsData);
      setIsConnected(true);
    } catch (error) {
      console.error("Error fetching connection details:", error);
      setIsConnected(false);
    }
  }, [clientInfo, companyType, callType, userPhone]);

  const onConfigureButtonClicked = useCallback(() => {
    setShowForm(true);
  }, []);

  const fetchData = async () => {
    try {
      refreshTokenIfNeeded()
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/communications/getBotInstructions/${userInfo?.clinica_id}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `${session.token_type} ${session.access_token}`,
            'Content-Type': 'application/json'
          },
        }
      );
      const data = await response.text();
      const formattedData = formatTextContent(data);
      setTextAreaContent(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const formatTextContent = (text) => {
    let formattedText = text.replace(/^"|"$/g, '');
    formattedText = formattedText.replace(/\\n/g, '\n');
    formattedText = formattedText.replace(/\n{3,}/g, '\n\n');
    return formattedText;
  };

  const saveData = async () => {
    try {
      refreshTokenIfNeeded()
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/communications/updateBotInstructions`,
        {
          method: 'PATCH',
          headers: {
            'Authorization': `${session.token_type} ${session.access_token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            company_id: userInfo.clinica_id,
            instructions: textAreaContent
          })
        }
      );

      if (response.ok) {
        toast.success(t('save_success'));
        fetchData();
      } else {
        toast.error(t('save_error'));
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  useEffect(() => {
    if (activeTab === 'textArea') {
      fetchData();
    }
  }, [activeTab]);

  if (loading) return <div>{t('loading')}</div>;
  if (!user || !userInfo) return <div>{t('no_user_data')}</div>;

  return (
    <div className="container mx-auto p-4 md:p-6" style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}>

      {activeTab === 'assistant' && (
        <div className="h-auto flex flex-col items-center bg-white text-white gap-8">
          <div className='flex w-full items-start gap-4'>
            <h1 className="text-7xl text-[#c2c2c2] font-bold">
              {isVeterinarian ? "MARÍA" : "MARÍA"}
            </h1>
            <div className='flex flex-col'>
              <p className="text-2xl text-[#34a4d0] font-semibold mt-2">{t('reception')}</p>
              <p className="text-2xl text-[#656565] font-semibold">{isVeterinarian ? "" : t('subtitle_3')}</p>
            </div>
          </div>
          <div className='flex w-full items-start gap-8'>
            <div className="flex flex-col px-20 pt-10 pb-64 rounded-sm justify-center items-center bg-white shadow-[0_0_5px_1px_rgba(0,0,0,0.1)]">
              <AnimatePresence>
                {showForm && !connectionDetails && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black/50 grid place-items-center p-4 z-50"
                  >
                    <ParticipantForm
                      setClientInfo={setClientInfo}
                      onSubmit={handleFormSubmit}
                      onCancel={() => setShowForm(false)}
                    />
                  </motion.div>
                )}
              </AnimatePresence>

              <LiveKitRoom
                token={connectionDetails?.participantToken}
                serverUrl={connectionDetails?.serverUrl}
                connect={connectionDetails !== undefined}
                audio={true}
                video={false}
                onMediaDeviceFailure={onDeviceFailure}
                onDisconnected={() => {
                  updateConnectionDetails(undefined);
                  setParticipantInfo(null);
                  setIsConnected(false);
                }}
                className="flex flex-col items-center"
              >
                <div className="flex justify-center items-center p-4 relative">
                  <div
                    className={`w-60 h-60 rounded-full overflow-hidden shadow-[0_0_20px_5px_rgba(0,0,0,0.2)] ${isConnected ? 'border-4 border-[#29920E]' : 'border-2 border-black'
                      }`}
                  >
                    <div className="w-full h-full rounded-full border border-white">
                      <img
                        src={isVeterinarian ? "/scribe_vet.png" : "/scribe.png"}
                        alt="Profile"
                        className="w-full h-full object-cover rounded-full p-4"
                      />
                    </div>
                  </div>
                  {isConnected && (
                    <div
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y bg-[#29920E] p-2 rounded-full"
                    >
                      <Mic size={18} className="text-white" />
                    </div>
                  )}
                </div>

                <RoomInfoComponent />
                <SimpleVoiceAssistant
                  onStateChange={setAgentState}
                  participantInfo={participantInfo}
                />
                <ControlBar
                  onConnectButtonClicked={onConnectButtonClicked}
                  onConfigureButtonClicked={onConfigureButtonClicked}
                  agentState={agentState}
                />
                <RoomAudioRenderer />
                <NoAgentNotification state={agentState} />
              </LiveKitRoom>
            </div>
            <ChatAssistant isVeterinarian={isVeterinarian} userPhone={userPhone} setUserPhone={setUserPhone} />
          </div>
          <div className="flex justify-end gap-4 mt-8 pr-4 w-full">
            <div
              onClick={() => handleIconClick("outgoing")}
              className={`w-12 h-12 rounded-full flex justify-center items-center cursor-pointer
              ${selectedIcon === "outgoing" ? "bg-transparent" : "bg-white"}`}
            >
              <img
                src="/asistente.svg"
                alt="Assistant Icon"
                className={`w-8 h-8 ${selectedIcon === "outgoing" ? "" : "filter grayscale brightness-75"
                  }`}
              />
            </div>

            <div
              onClick={() => handleIconClick("incoming")}
              className={`w-12 h-12 rounded-full flex justify-center items-center cursor-pointer
              ${selectedIcon === "incoming" ? "bg-transparent" : "bg-white "}`}
            >
              <img
                src="/paciente.svg"
                alt="Patient Icon"
                className={`w-8 h-8 ${selectedIcon === "incoming" ? "" : "filter grayscale brightness-75"
                  }`}
              />
            </div>
          </div>

        </div>
      )}
      {activeTab === 'textArea' && (
        <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
          <TextArea
            value={textAreaContent}
            onChange={setTextAreaContent}
            onSave={saveData}
          />
        </div>
      )}
    </div>
  );
}

const SimpleVoiceAssistant = ({ onStateChange, participantInfo }) => {
  const { state, audioTrack } = useVoiceAssistant();
  const { t } = useTranslation();

  useEffect(() => {
    onStateChange(state);
  }, [onStateChange, state]);

  if (state === "disconnected") {
    return null;
  }

  return (
    <div className="flex h-[100px] w-[300px] mb-8 mx-auto">
      <BarVisualizer
        state={state}
        barCount={6}
        trackRef={audioTrack}
        className="agent-visualizer"
        options={{ minHeight: 32 }}
      />
    </div>
  );
}

function ControlBar(props) {
  const { t } = useTranslation();

  return (
    <div className="relative h-[100px] w-[300px]">
      <AnimatePresence>
        {props.agentState === "disconnected" && (
          <div className='flex flex-row justify-center gap-4'>
            <motion.button
              initial={{ opacity: 0, top: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, top: "-10px" }}
              transition={{ duration: 1, ease: [0.09, 1.04, 0.245, 1.055] }}
              className="uppercase p-3 bg-[#35a4d1] text-white rounded-full"
              onClick={() => props.onConnectButtonClicked()}
            >
              <Phone />
            </motion.button>
            {/* <motion.button
              initial={{ opacity: 0, top: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, top: "-10px" }}
              transition={{ duration: 1, ease: [0.09, 1.04, 0.245, 1.055] }}
              className="uppercase p-3 bg-black text-white rounded-full"
              onClick={() => props.onConfigureButtonClicked()}
            >
              <UserPen />
            </motion.button> */}
          </div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {props.agentState !== "disconnected" &&
          props.agentState !== "connecting" && (
            <motion.div
              initial={{ opacity: 0, top: "10px" }}
              animate={{ opacity: 1, top: 0 }}
              exit={{ opacity: 0, top: "-10px" }}
              transition={{ duration: 0.4, ease: [0.09, 1.04, 0.245, 1.055] }}
              className="flex h-8 w-full absolute left-1/2 -translate-x-1/2  justify-center"
            >
              <DisconnectButton style={{ borderRadius: 99, backgroundColor: "#F04848" }}>
                <X />
              </DisconnectButton>
            </motion.div>
          )}
      </AnimatePresence>
    </div>
  );
}

function RoomInfoComponent() {
  const room = useRoomContext();
  const participants = useParticipants();
  const roomInfo = useRoomInfo({ room: room || null });
  const connectionState = useConnectionState(room || null);

  useEffect(() => {
    console.log("Current Room:", room);
    console.log("Current Participants:", participants);
    console.log("Current Room Info:", roomInfo);
    console.log("Current Connection State:", connectionState);
  }, [room, participants, roomInfo, connectionState]);

  return null;
}

function onDeviceFailure(error) {
  console.error(error);
  alert(
    "Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab"
  );
}
